import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { NamedExoticComponent, useEffect, useState } from 'react';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import { TextField } from '@mui/material';
import ButtonWithTooltip from 'src/components/ButtonWithTooltip';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { useQuery } from '@tanstack/react-query';
import { MUTATION_KEYS, getAuthorizations } from 'src/app/api';
import { AUTHORIZATION_STATUSES, BUTTON_STATE, PAYER_TYPES, PayerType } from '../../../../../app/helpers/enum';
import FormExpandableField from '../../../../../components/Form/FormExpandableField';
import { useForm } from '../../../../../app/hooks';
import './PayerField.scss';
import InsuranceSection from './InsuranceSection/InsuranceSection';
import { findInOptions } from '../../../../../app/helpers/manipulation';
import AgencySection from './AgencySection/AgencySection';
import { ValuesOf } from '../../../../../app/types/TypeMappers';
import AuthorizationsSection from './AuthorizationsSection/AuthorizationsSection';
import { DatabaseId } from '../../../../../app/types/DataStructures';
import { Company, Payer } from '../../../../../app/types/Entities';
import { mapObjectForSelect } from '../../../../../app/helpers/mappers';
import { Claim } from '../CaseField/localTypes';
import LawfirmSection from './LawfirmSection/LawfirmSection';
import { FormSelectEntry } from '../../../../../app/types/Components';
import { PayerSectionProps } from './localTypes';
import FormSpan from '../../../../../components/Form/FormSpan';
import { isEventPayerStateValid } from '../../../../../app/helpers/services';
import { PayerFieldState } from '../stateTypes';

export interface PayerFieldProps {
  showPayerField: boolean;
  setShowPayerField: (display: boolean) => void;

  claim: Claim | null;
  setClaim: (claim: Claim | null) => void;

  payer: Payer | null;
  setPayer: (payer: Payer | null) => void;
  payerCompany: Company | null;
  setPayerCompany: (payerCompany: Company | null) => void;
  payerType: PayerType;
  setPayerType: (payerType: PayerType) => void;
  initialPayerFieldState: PayerFieldState;
}

const SECTION_DISPLAY = {
  PAYER_DATA: 0,
  AUTHORIZATIONS: 1,
} as const;

const PAYER_SECTION_MAP: Record<string, NamedExoticComponent<PayerSectionProps>> = {
  [PAYER_TYPES.AGENCY]: AgencySection as NamedExoticComponent<PayerSectionProps>,
  [PAYER_TYPES.INSURANCE]: InsuranceSection as NamedExoticComponent<PayerSectionProps>,
  [PAYER_TYPES.LAWYER]: LawfirmSection as NamedExoticComponent<PayerSectionProps>,
} as const;

const formPrefix = 'payer-claim';
const translationArray = ['booking', 'forms', 'languages'];

function PayerField(props: PayerFieldProps) {
  const {
    showPayerField,
    setShowPayerField,
    claim,
    setClaim,
    payer,
    setPayer,
    payerCompany,
    setPayerCompany,
    payerType,
    setPayerType,
    initialPayerFieldState,
  } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Form data
  const [openSection, setOpenSection] = useState<ValuesOf<typeof SECTION_DISPLAY>>(SECTION_DISPLAY.PAYER_DATA);

  const payerTypeOptions = mapObjectForSelect(PAYER_TYPES, (_, value) => t(value));

  const onPayerTypeChange = (_event: unknown, payerTypeOption: FormSelectEntry<PayerType>) => {
    if (!payerTypeOption) {
      return;
    }

    setPayerType(payerTypeOption.value);
    setPayerCompany(null);
    setPayer(null);
  };

  const isPayerSectionVisible = Object.keys(PAYER_SECTION_MAP).includes(payerType);

  // Query

  const authorizationsFilters = { event_id: claim?.eventId };

  const [authorizationsRefetchEnabled, setAuthorizationsRefetchEnabled] = useState<boolean>(true);
  const {
    data: authorizations,
    refetch: refetchAuthorizations,
    isLoading: areAuthorizationsLoading,
  } = useQuery([MUTATION_KEYS.AUTHORIZATION, authorizationsFilters], () => getAuthorizations(authorizationsFilters), {
    enabled: authorizationsRefetchEnabled,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (authorizations) {
      setAuthorizationsRefetchEnabled(false);
    }
  }, [authorizations]);

  const existsApprovedAuthorization = !areAuthorizationsLoading
    ? !!authorizations?.find(
        ({ status }) =>
          status === AUTHORIZATION_STATUSES.ACCEPTED ||
          status === AUTHORIZATION_STATUSES.OVERRIDE ||
          status === AUTHORIZATION_STATUSES.REFERRED,
      )
    : false;

  // Display
  const label = (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ flex: '0 0 auto' }}>
        <h5>{t`payer`}</h5>
      </div>
      <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start', paddingLeft: '10px' }}>
        {!isPayerSectionVisible || isEventPayerStateValid(payer, payerCompany, payerType) ? null : (
          <MoneyOffIcon className="no-payer-icon" />
        )}
      </div>
    </div>
  );

  const display = (
    <Container className="p-0">
      <Row className="gx-2">
        <Col md={12} xl={6} xxl={4}>
          <FormSelect
            id={p`payer`}
            options={payerTypeOptions}
            value={findInOptions(payerTypeOptions, payerType)}
            onChange={onPayerTypeChange}
            renderInput={(params) => <TextField {...params} />}
            disableClearable
          />
        </Col>
        <Col md={12} xl={6}>
          <FormSpan>
            {!isPayerSectionVisible || isEventPayerStateValid(payer, payerCompany, payerType)
              ? t`payerValid`
              : t`payerInvalid`}
          </FormSpan>
        </Col>
        <Col xs={12} xxl={2}>
          {(payerType === PAYER_TYPES.INSURANCE || payerType === PAYER_TYPES.AGENCY) && (
            <ButtonWithTooltip
              id={p`authorization-status-button`}
              className="authorization-status-button w-100 mb-3"
              color={
                authorizations?.find(({ status }) => status === AUTHORIZATION_STATUSES.ACCEPTED)
                  ? 'accepted'
                  : authorizations?.find(({ status }) => status === AUTHORIZATION_STATUSES.OVERRIDE)
                  ? 'override'
                  : authorizations?.find(({ status }) => status === AUTHORIZATION_STATUSES.REFERRED)
                  ? 'referred'
                  : authorizations?.find(({ status }) => status === AUTHORIZATION_STATUSES.REJECTED)
                  ? 'rejected'
                  : authorizations?.find(({ status }) => status === AUTHORIZATION_STATUSES.PENDING)
                  ? 'pending'
                  : 'primary'
              }
              disabled={!claim?.eventId || (showPayerField && openSection === SECTION_DISPLAY.AUTHORIZATIONS)}
              tooltip={t`tooltip.viewAuthorizations`}
              onClick={() => {
                setOpenSection(SECTION_DISPLAY.AUTHORIZATIONS);
                setShowPayerField(true);
              }}
              variant="contained"
            >
              {authorizations?.find(({ status }) => status === AUTHORIZATION_STATUSES.ACCEPTED)
                ? t`buttons.authorized`
                : authorizations?.find(({ status }) => status === AUTHORIZATION_STATUSES.OVERRIDE)
                ? t`buttons.override`
                : authorizations?.find(({ status }) => status === AUTHORIZATION_STATUSES.REFERRED)
                ? t`buttons.referred`
                : authorizations?.find(({ status }) => status === AUTHORIZATION_STATUSES.REJECTED)
                ? t`buttons.rejected`
                : authorizations?.find(({ status }) => status === AUTHORIZATION_STATUSES.PENDING)
                ? t`buttons.pending`
                : t`buttons.notAuthorized`}
            </ButtonWithTooltip>
          )}
        </Col>
      </Row>
    </Container>
  );

  const toggleOpen = (isOpen: boolean) => {
    setShowPayerField(isOpen);
    if (isOpen) {
      setOpenSection(SECTION_DISPLAY.PAYER_DATA);
    }
  };

  const onAuthorizationCreated = (authorizationId: DatabaseId) =>
    claim && setClaim({ ...claim, authorizationIds: [...(claim.authorizationIds ?? []), authorizationId] });

  const PayerSection = PAYER_SECTION_MAP[payerType] ?? (() => null);

  const onCancel = () => {
    setShowPayerField(false);
  };

  return (
    <FormExpandableField
      alignButtonsBottom
      formPrefix={p`payer`}
      isOpen={isPayerSectionVisible && showPayerField}
      toggleOpen={toggleOpen}
      openButtonState={isPayerSectionVisible ? BUTTON_STATE.INTERACTIVE : BUTTON_STATE.HIDDEN}
      label={label}
      display={display}
      onCancel={onCancel}
    >
      {openSection === SECTION_DISPLAY.PAYER_DATA ? (
        <PayerSection
          formPrefix={p(payerType)}
          payer={payer}
          setPayer={setPayer}
          payerCompany={payerCompany}
          setPayerCompany={setPayerCompany}
          initialPayerFieldState={initialPayerFieldState}
        />
      ) : openSection === SECTION_DISPLAY.AUTHORIZATIONS ? (
        <AuthorizationsSection
          formPrefix={p`authorizations`}
          claim={claim}
          authorizations={authorizations}
          refetchAuthorizations={refetchAuthorizations}
          onAuthorizationCreated={onAuthorizationCreated}
          payerType={payerType}
          payerCompanyId={payerCompany?.id ?? null}
          toggleShowPayerField={setShowPayerField}
          existsApprovedAuthorization={existsApprovedAuthorization}
        />
      ) : null}
    </FormExpandableField>
  );
}

export default PayerField;

import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import { TextField } from '@mui/material';
import Swal from 'sweetalert2';
import { PREFERRED_DATE_FORMAT_MOMENT } from 'src/app/helpers/constants';
import moment from 'moment-timezone';
import { getPreferredAgenciesWithCompanies } from 'src/app/api/preferred_agencies';
import { BUTTON_STATE, CASE_TYPES, CaseType } from '../../../../../app/helpers/enum';
import FormExpandableField from '../../../../../components/Form/FormExpandableField';
import { useForm } from '../../../../../app/hooks';
import { Claim } from './localTypes';
import {
  EventsGetParams,
  EventsPatchError,
  EventsPatchPayload,
  EventsPatchResponse,
  getAgenciesWithRoles,
  getEvents,
  MUTATION_KEYS,
  patchEvents,
} from '../../../../../app/api';
import { arrayFilterDuplicates, findInOptions, stringifyDate } from '../../../../../app/helpers/manipulation';
import { mapArrayForSelect, mapObjectForSelect } from '../../../../../app/helpers/mappers';
import { isNotNil } from '../../../../../app/helpers/sanitizers';
import {
  BEvent,
  BEventShallow,
  Recipient,
  Location,
  User,
  BookingStatus,
  Agency,
  Company,
} from '../../../../../app/types/Entities';
import ClaimSection from './ClaimSection';
import FormSelectCreatable from '../../../../../components/Form/FormSelectCreatable/FormSelectCreatable';
import { PayerFieldState } from '../stateTypes';
import './CaseField.scss';

export const isClaimValid = (claim: Partial<Claim>): claim is Claim =>
  !!(claim.claimNumber && claim.dateOfInjury && claim.diagnosis);

export const extractClaimFromEvent = (event: BEvent | BEventShallow): Claim | null =>
  !event.claim_number && !event.date_of_injury && !event.diagnosis
    ? null
    : {
        _newlyCreated: false,
        authorizationIds: event.authorizations?.map(({ id }) => id!) ?? undefined,
        eventId: event.id,
        claimNumber: event.claim_number!,
        dateOfInjury: event.date_of_injury!,
        diagnosis: event.diagnosis ?? '',
        insuranceId: event.claim_insurance_id,
        adjusterId: event.claim_adjuster_id,
        lawfirmId: event.claim_lawfirm_id,
        lawyerId: event.claim_lawyer_id,
      };

export interface CaseFieldProps {
  user: Omit<User, never> | undefined;
  showCaseField: boolean;
  setShowCaseField: (display: boolean) => void;
  bookingExists: boolean;
  bookingStatus?: BookingStatus;
  patient: Omit<Recipient, 'affiliations'> | undefined;

  caseType: CaseType;
  setCaseType: (caseType: CaseType) => void;

  claim: Claim | null;
  setClaim: (claim: Claim | null) => void;
  agency: Agency | null;
  setAgency: (agency: Agency | null) => void;
  parentSubmitClaim: (claim: Claim) => Promise<void>;
  onSubmitSuccess: (claim: Claim) => void;
  initialPayerFieldState: PayerFieldState;
  payerCompany: Company | null;
  start_at: string;
  clinic: string | undefined;
  contactClinic?: Location;
  medicalProvider: string | undefined;
  medicalProviderLastName: string | undefined;
  serviceRoot: string | undefined;
  serviceProvider: string | undefined;
  serviceProviderLastName: string | undefined;
  typeOfCertification: string | undefined;
  duration: Date;
  patient_first_name: string | undefined;
  patient_last_name: string | undefined;
}

const formPrefix = 'case';
const translationArray = ['booking', 'collections'];

function CaseField(props: CaseFieldProps) {
  const {
    user,
    showCaseField,
    setShowCaseField,
    bookingExists,
    patient,
    caseType,
    setCaseType,
    claim,
    setClaim: propsSetClaim,
    agency,
    setAgency,
    parentSubmitClaim,
    onSubmitSuccess,
    initialPayerFieldState,
    payerCompany,
    start_at,
    clinic,
    contactClinic,
    medicalProvider,
    medicalProviderLastName,
    serviceRoot,
    serviceProvider,
    serviceProviderLastName,
    typeOfCertification,
    duration,
    patient_first_name,
    patient_last_name,
  } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Form data
  const [createClaimMode, setCreateClaimMode] = useState<boolean>(false);
  const [stagedForCreation, setStagedForCreation] = useState<boolean>(false);

  const caseTypeOptions = mapObjectForSelect(CASE_TYPES, (_, value) => t(`collections:caseTypes.${value}`));

  const [initialClaimNumber, setInitialClaimNumber] = useState<string | undefined>();

  const setClaim = (newClaim: Claim | null) => {
    propsSetClaim(newClaim);
    setInitialClaimNumber(undefined);
  };

  // Query
  // Get all events that have a non-null claim and DoI, related to the patient
  const eventFilters = {
    recipient_id: patient?.id,
  };

  const [eventsRefetchEnabled, setEventsRefetchEnabled] = useState<boolean>(true);
  const {
    data: eventsWithClaim,
    isLoading: areEventsLoading,
    fetchStatus: eventsFetchStatus,
  } = useQuery(
    [MUTATION_KEYS.EVENTS, eventFilters],
    () =>
      patient?.id ? getEvents(eventFilters as EventsGetParams, {}) : Promise.reject(new Error('Pacient id not set')),
    {
      enabled: eventsRefetchEnabled,
    },
  );

  useEffect(() => {
    if (eventsWithClaim) {
      setEventsRefetchEnabled(false);
    }
  }, [eventsWithClaim]);

  // Get all Agencies
  const [preferredAgenciesRefetchEnabled, setPreferredAgenciesRefetchEnabled] = useState<boolean>(true);
  const { data: preferredAgencies } = useQuery(
    [MUTATION_KEYS.COMPANIES, payerCompany?.id],
    () =>
      getPreferredAgenciesWithCompanies({ company_from: payerCompany?.id })
        .then((res) => res.map((v) => v.company_to as Agency))
        .then((res) => res.filter((v) => isNotNil(v))),
    { enabled: preferredAgenciesRefetchEnabled },
  );

  useEffect(() => {
    if (!payerCompany || !payerCompany?.id || caseType !== 'referredToAgency' || preferredAgencies) {
      setPreferredAgenciesRefetchEnabled(false);
    }
  }, [caseType, payerCompany, preferredAgencies]);

  const [agenciesRefetchEnabled, setAgenciesRefetchEnabled] = useState<boolean>(true);
  const { data: agencies } = useQuery([MUTATION_KEYS.COMPANIES], () => getAgenciesWithRoles({}), {
    enabled: agenciesRefetchEnabled,
  });

  useEffect(() => {
    if (caseType !== 'referredToAgency' || agencies) {
      setAgenciesRefetchEnabled(false);
    }
  }, [agencies, caseType]);

  const agenciesOptions = () => {
    if (caseType !== 'referredToAgency') return [];

    let options = mapArrayForSelect(agencies ?? [], (agencyOption) => agencyOption.name, 'Other Agencies');
    options = options.concat(
      mapArrayForSelect(preferredAgencies ?? [], (agencyOption) => agencyOption!.name, 'Preferred Agencies'),
    );

    return options;
  };

  const claims = eventsWithClaim
    ?.map(extractClaimFromEvent)
    .filter<Claim>((iterClaim): iterClaim is Claim => isNotNil(iterClaim)) // Exclude malformed or partial claims
    .filter(arrayFilterDuplicates('claimNumber'));

  // Mutation
  const { mutateAsync: mutateAsyncPatchEvents } = useMutation<
    EventsPatchResponse,
    EventsPatchError,
    Parameters<typeof patchEvents>
  >((args) => patchEvents(...args));

  // Validate
  const validate = () => {
    if (!eventsWithClaim) {
      toast.error('Did not finish fetching all events, please try again in a few seconds');
      return false;
    }
    return true;
  };

  // On update
  const submitClaimUpdate = (newClaim: Claim) => {
    if (!validate()) {
      return Promise.reject();
    }

    // Build payload
    const patchEventsPayload: EventsPatchPayload = {
      claim_number: newClaim?.claimNumber,
      date_of_injury: newClaim?.dateOfInjury ? stringifyDate(newClaim?.dateOfInjury) : undefined,
      diagnosis: newClaim?.diagnosis ?? undefined,
      claim_insurance_id: newClaim?.insuranceId ?? undefined,
    };

    // Update event
    const query = { claim_number: newClaim.claimNumber };

    return toast
      .promise(mutateAsyncPatchEvents([query, patchEventsPayload]), {
        pending: t`progress.claimUpdate.started` as string,
        error: t`progress.claimUpdate.error` as string,
        success: t`progress.claimUpdate.success` as string,
      })
      .catch((err) => {
        console.error('There was an error updating the event(s) via events/', err);
        return Promise.reject();
      });
  };

  const submitClaim = async (newClaim: Claim) => {
    setStagedForCreation(createClaimMode);
    if (!createClaimMode) {
      await submitClaimUpdate(newClaim);
    }
    await parentSubmitClaim(newClaim);
    onSubmitSuccess(newClaim);
  };

  // Display
  const label = (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ flex: '0 0 auto' }}>
        <h5>{t`case`}</h5>
      </div>
      <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start', paddingLeft: '10px' }}>
        {claim === null ? <WorkOffIcon className="no-case-icon" /> : null}
      </div>
    </div>
  );

  const display = (
    <Container className="p-0">
      <Row className="gx-2">
        <Col md={12} xl={6} xxl={4}>
          <FormSelect
            disableClearable
            id={p`caseType`}
            options={caseTypeOptions}
            value={findInOptions(caseTypeOptions, caseType)}
            onChange={(_, option) => setCaseType(option?.value)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Col>
        <Col md={12} xl={8}>
          {caseType !== 'referredToAgency' && (
            <FormSelectCreatable<Claim>
              fullWidth
              clearOnBlur
              id={p`claim`}
              options={claims ?? []}
              value={createClaimMode && !stagedForCreation ? null : claim}
              disabled={!patient || createClaimMode}
              loading={areEventsLoading && eventsFetchStatus === 'fetching'}
              getCreatedOptionLabel={() => t`addClaim`}
              getSelectedOptionLabel={({ claimNumber, dateOfInjury }) =>
                `#${claimNumber} — DoI: ${stringifyDate(dateOfInjury)}`
              }
              onCreateOption={(inputValue) => {
                setClaim(null);
                setInitialClaimNumber(inputValue);
                setShowCaseField(true);
                setCreateClaimMode(true);
                setStagedForCreation(false);
              }}
              componentsProps={{
                clearIndicator: {
                  onClick: () => {
                    Swal.fire({
                      title: t`alerts.removeClaimNumber.title`,
                      text: t`alerts.removeClaimNumber.text`,
                      icon: 'warning',
                      confirmButtonText: t`actions.remove`,
                      confirmButtonColor: '#00a2b8',
                      showCancelButton: true,
                      cancelButtonText: t`actions.cancel`,
                      cancelButtonColor: '#dc3741',
                      allowOutsideClick: true,
                      customClass: {
                        confirmButton: 'btn action-button',
                        cancelButton: 'btn action-button',
                      },
                    }).then((result) => {
                      if (result.isConfirmed) {
                        setClaim(null);
                      }
                    });
                  },
                },
              }}
              onSelectOption={async (selectedClaim) => {
                if (!selectedClaim) {
                  setShowCaseField(false);
                }

                setClaim(selectedClaim);

                let canCreate = true;

                if (claims?.length! > 1) {
                  canCreate = await Swal.fire({
                    title: t`alerts.multipleClaimNumbers.title`,
                    html:
                      `<p>
                        ${t`alerts.multipleClaimNumbers.text`}
                      <p>` +
                      `<ul>
                      ${claims!
                        .map(
                          (c) =>
                            `<li> ${t`alerts.multipleClaimNumbers.claimNumber`} ${c.claimNumber} 
                            ${t`alerts.multipleClaimNumbers.doi`} ${moment(c.dateOfInjury)
                              .utc()
                              .format(PREFERRED_DATE_FORMAT_MOMENT)} 
                              ${t`alerts.multipleClaimNumbers.diagnosis`} ${c.diagnosis}</li>`,
                        )
                        .join('')} 
                      </ul>`,
                    icon: 'warning',
                    confirmButtonText: t`actions.continue`,
                    confirmButtonColor: '#00a2b8',
                    showCancelButton: true,
                    cancelButtonText: t`actions.cancel`,
                    cancelButtonColor: '#dc3741',
                    allowOutsideClick: true,
                    customClass: {
                      confirmButton: 'btn action-button',
                      cancelButton: 'btn action-button',
                    },
                  }).then(({ isConfirmed }) => isConfirmed);
                }

                if (!canCreate) {
                  setClaim(null);
                }
              }}
              getOptionValue={(option) => option.claimNumber}
              renderInput={(params) => (
                <TextField placeholder={createClaimMode ? t`newClaim` : t`selectClaim`} {...params} />
              )}
            />
          )}
          {caseType === 'referredToAgency' && (
            <FormSelect
              disableClearable
              id={p`agencyName`}
              options={agenciesOptions().sort((a, b) => b.groupId!.localeCompare(a.groupId!))}
              groupBy={(option) => option.groupId ?? 'Other Agencies'}
              value={findInOptions(agenciesOptions(), agency)}
              onChange={(_, option) => setAgency(option?.value)}
              renderInput={(params) => <TextField placeholder={t`selectAgency`} {...params} />}
            />
          )}
        </Col>
      </Row>
    </Container>
  );

  const onCancel = () => {
    setShowCaseField(false);
    setCreateClaimMode(false);
  };

  return (
    <FormExpandableField
      alignButtonsBottom
      formPrefix={p`case`}
      isOpen={showCaseField}
      toggleOpen={setShowCaseField}
      canClose={!createClaimMode || stagedForCreation}
      openButtonState={
        !claim || (createClaimMode && !stagedForCreation) ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE
      }
      label={label}
      display={display}
      onCancel={onCancel}
    >
      <ClaimSection
        user={user}
        formPrefix={p`claim`}
        createMode={createClaimMode}
        toggleCreateMode={setCreateClaimMode}
        bookingExists={bookingExists}
        toggleShowPayerClaimField={setShowCaseField}
        initialClaimNumber={initialClaimNumber}
        claim={claim}
        submitClaim={submitClaim}
        caseType={caseType}
        initialPayerFieldState={initialPayerFieldState}
        start_at={start_at}
        clinic={clinic}
        contactClinic={contactClinic}
        medicalProvider={medicalProvider}
        medicalProviderLastName={medicalProviderLastName}
        serviceRoot={serviceRoot}
        serviceProvider={serviceProvider}
        serviceProviderLastName={serviceProviderLastName}
        typeOfCertification={typeOfCertification}
        duration={duration}
        noteDiagnosis={claim?.diagnosis}
        patient_first_name={patient_first_name}
        patient_last_name={patient_last_name}
      />
    </FormExpandableField>
  );
}

export default CaseField;

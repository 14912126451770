import { Ref, useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import BookingsList from 'src/pages/BookingsPage/List/List';
import Swal from 'sweetalert2';
import { useSearchParams } from 'react-router-dom';
import { Alert, AlertTitle, Box, CircularProgress, useTheme } from '@mui/material';
import { Button, Col, Container, FormText, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { BEvent, Booking, BookingStatus } from 'src/app/types/Entities';
import { DEFAULT_BOOKING_STAGE_COLOR } from 'src/app/helpers/constants';
import BookingShareButton from 'src/components/BookingShareButton/BookingShareButton';
import { useQuery } from '@tanstack/react-query';
import {
  BookingsPutMarked,
  EventsGetParams,
  MUTATION_KEYS,
  getEventsPaginated,
  getLightEventsPaginated,
  updateEvent,
} from 'src/app/api';
import { useStateDict } from 'src/app/hooks';
import classNames from 'classnames';
import { LightBEvent } from 'src/app/api/config/light_entities';
import { GridSortModel } from '@mui/x-data-grid';
import { DisplayBookingModalFunction } from 'src/pages/BookingsPage/localTypes';
import { stringifyDateTime } from 'src/app/helpers/manipulation';
import { toast } from 'react-toastify';

interface InvoicingProps {
  invoicable: boolean;
  exportable: boolean;
}

const translationArray = ['viewers', 'booking', 'calendar'];

export default function Invoicing({ invoicable, exportable }: InvoicingProps) {
  const { t } = useTranslation(translationArray);
  const theme = useTheme();

  // Modal
  const [searchParams] = useSearchParams();
  const saveRef = useRef<HTMLButtonElement>();

  const paramEventId = searchParams.get('eventId');

  // Pagination
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  // List Query
  const [eventFiltersList, setEventFiltersList] = useStateDict<EventsGetParams>({
    page: paginationModel.page + 1,
    page_size: paginationModel.pageSize,
    field_to_sort: 'payer_company__send_method',
    order_to_sort: 'desc',
  });
  const [eventFiltersListHeavy, setEventFiltersListHeavy] = useStateDict<EventsGetParams>({
    page: paginationModel.page + 1,
    page_size: paginationModel.pageSize,
    items_included: ['delivered'],
    items_excluded: ['marked_as_invoiced'],
    field_to_sort: 'payer_company__send_method',
    order_to_sort: 'desc',
  });

  const handleSortModelChange = (sortModel: GridSortModel) => {
    let fieldToSort = '';
    switch (sortModel[0]?.field) {
      case 'provider':
        fieldToSort = 'booking__services__provider__user__first_name';
        break;
      case 'sendMethod':
        fieldToSort = 'payer_company__send_method';
        break;
      case 'public_id':
        fieldToSort = 'booking__public_id';
        break;
      case 'patient':
        fieldToSort = 'affiliates__recipient__user__first_name';
        break;
      default:
        fieldToSort = 'booking__companies__name';
    }
    // Here you save the data you need from the sort model
    setEventFiltersList({ ...eventFiltersList, field_to_sort: fieldToSort, order_to_sort: sortModel[0].sort });
    setEventFiltersListHeavy({
      ...eventFiltersListHeavy,
      field_to_sort: fieldToSort,
      order_to_sort: sortModel[0].sort,
    });
  };
  const [eventsRefetchEnabled, seteventsRefetchEnabled] = useState<boolean>(true);

  const { data: eventsListLightData, isLoading: isLoadingListData } = useQuery(
    [MUTATION_KEYS.EVENTS_LIGHT, eventFiltersList],
    () => getLightEventsPaginated(eventFiltersList),
    {
      enabled: eventsRefetchEnabled,
    },
  );

  useEffect(() => {
    seteventsRefetchEnabled(true);
  }, [eventFiltersList]);

  useEffect(() => {
    if (eventsListLightData) {
      seteventsRefetchEnabled(false);
    }
  }, [eventsListLightData]);

  useEffect(() => {
    setEventFiltersList({
      ...eventFiltersList,
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
    });
    setEventFiltersListHeavy({
      ...eventFiltersListHeavy,
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
      items_included: ['delivered'],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel, setEventFiltersList, setEventFiltersListHeavy]);

  const [filteredEvents, setFilteredEvents] = useState<BEvent[] | LightBEvent[]>([]);
  const [isHeavyLoadedList, setIsHeavyLoadedList] = useState(false);

  const { data: eventsListHeavyData, isLoading: isLoadingListHeavyData } = useQuery(
    [MUTATION_KEYS.EVENTS, eventFiltersListHeavy],
    () => {
      const currentPath = window.location.pathname;
      if (currentPath !== '/admin') {
        return undefined;
      }
      return getEventsPaginated(eventFiltersListHeavy);
    },
  );

  useEffect(() => {
    if (isHeavyLoadedList && eventsListHeavyData) {
      setFilteredEvents(eventsListHeavyData.results ?? []);
    } else if (!isLoadingListData && eventsListLightData) {
      setFilteredEvents(eventsListLightData.results ?? []);
    }
  }, [isHeavyLoadedList, eventsListHeavyData, isLoadingListData, eventsListLightData]);

  useEffect(() => {
    if (!isLoadingListHeavyData && eventsListHeavyData) {
      setIsHeavyLoadedList(true);
    }
  }, [isLoadingListHeavyData, eventsListHeavyData]);

  const [rowCountState, setRowCountState] = useState(eventsListHeavyData?.count || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      eventsListHeavyData?.count !== undefined ? eventsListHeavyData.count : prevRowCountState,
    );
  }, [eventsListHeavyData?.count, setRowCountState]);

  const [booking, setBooking] = useState<Booking | null>(null);
  const [bookingPublicId, setBookingPublicId] = useState<string | null>(null);
  const [modalBookingStatus, setModalBookingStatus] = useState<BookingStatus | undefined>();
  const [bookingPreloadStatus, setBookingPreloadStatus] = useState<'loading' | 'success' | 'error' | undefined>();
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [checkIsDirty] = useState<() => { isDirty: boolean; fieldsDirty: string[] }>(() => () => ({
    isDirty: false,
    fieldsDirty: [],
  }));

  useEffect(() => {
    // Function to display a confirmation message
    const handleBeforeUnload = (ev: BeforeUnloadEvent) => {
      const { isDirty } = checkIsDirty();
      if (isDirty) {
        ev.preventDefault();
        // eslint-disable-next-line no-param-reassign
        ev.returnValue = t`errors.unsavedChanges`;
      }
    };

    // Add the event listener when component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [checkIsDirty, t]);

  const hideBookingModal = async (isSaving: boolean) => {
    const { isDirty, fieldsDirty } = checkIsDirty();

    let canClose = true;
    let swalResponse;
    const text = t`fieldsWithChanges` + fieldsDirty.map((field) => t(`fields.${field}`)).join(', ');

    if (isDirty && !isSaving) {
      canClose = false;

      swalResponse = await Swal.fire({
        title: t`errors.unsavedChanges`,
        icon: 'warning',
        text,
        confirmButtonText: t`actions.saveAndClose`,
        confirmButtonColor: '#00a2b8',
        showDenyButton: true,
        denyButtonText: t`actions.keepEditing`,
        denyButtonColor: '#6e7881',
        showCancelButton: true,
        cancelButtonText: t`actions.discard`,
        cancelButtonColor: '#dc3741',
        allowOutsideClick: true,
        width: '40em',
        customClass: {
          confirmButton: 'btn action-button',
          denyButton: 'btn action-button',
          cancelButton: 'btn action-button',
        },
      }).then((result) => result);
    }

    if (swalResponse?.isDenied) {
      return;
    }

    if (swalResponse?.isConfirmed) {
      saveRef.current?.click();
    }

    if (swalResponse?.isDismissed || canClose) {
      setShowBookingModal(false);
      setBooking(null);
      setBookingPublicId(null);
      setModalBookingStatus(undefined);
      setBookingPreloadStatus(undefined);
    }
  };

  const displayBookingModal: DisplayBookingModalFunction = (
    publicId: string | null,
    _startDate: Date | undefined,
    _endDate: Date | undefined,
    bookingStatus: BookingStatus | undefined,
    preloadStatus: 'loading' | 'success' | 'error' | undefined,
    bookingToDisplay: Booking | null = null,
  ) => {
    setBookingPublicId(publicId);
    // setEventStartDate(startDate);
    // setEventEndDate(endDate);
    setModalBookingStatus(bookingStatus);
    setShowBookingModal(true);
    setBooking(bookingToDisplay);
    if (preloadStatus === undefined) {
      setBookingPreloadStatus('loading');
    }
  };

  useEffect(() => {
    if (paramEventId === null) {
      setShowBookingModal(false);
    }
  }, [setShowBookingModal, paramEventId]);

  const buildEventPayload = (event: any) => {
    const Afilliates = event.affiliates.map((row: any) => {
      return [row.id];
    });
    const bookingsWithMarked: BookingsPutMarked | undefined = {
      id: event.id,
      booking: event.booking.id,
      affiliates: Afilliates[0],
      agents: [event.agents?.[0].id],
      requester: event.requester?.id,
      description: event.description,
      payer_company: event.payer_company.id,
      payer: event.payer?.id ?? null,
      start_at: stringifyDateTime(event.start_at),
      end_at: stringifyDateTime(event.end_at),
      marked_as_invoiced: true,
    };
    return bookingsWithMarked;
  };
  const bookingEvents = eventsListHeavyData?.results.map(buildEventPayload);

  const updateEvents = (data: any, events: any) => {
    data.map((dataItem: any) => {
      events.map((eventItem: any) => {
        if (eventItem.id === dataItem) {
          updateEvent(dataItem, eventItem)
            .then(() => {
              toast.success(`${t`booking.markedAsInvoiced`}`);
            })
            .catch(() => {
              toast.error(`${t('booking.errorMarkAsInvoiced', { Code: eventItem.id })}`);
            });
        }
        return '';
      });
      return '';
    });
  };

  return (
    <Box className="w-100 h-100 mw-100 p-0 container" sx={{ display: 'flex' }}>
      <Box
        component="main"
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginLeft: 0,
          minHeight: 0,
          minWidth: 0,
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <BookingsList
          invoicable={invoicable}
          exportable={exportable}
          updateEvents={updateEvents}
          bookingEvents={bookingEvents}
          filteredEvents={filteredEvents}
          displayBookingModal={displayBookingModal}
          bookingPreloadStatus={bookingPreloadStatus}
          paramEventId={paramEventId}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          rowCount={rowCountState}
          handleSortModelChange={handleSortModelChange}
          isLoading={isLoadingListHeavyData || isLoadingListData}
        />
      </Box>

      <Modal
        fullscreen
        className="booking-modal"
        isOpen={showBookingModal}
        toggle={() => hideBookingModal(true)}
        keyboard={false}
      >
        <ModalHeader className="booking-modal-header">
          <Row className="align-items-center">
            <Col>
              <Button
                innerRef={saveRef as Ref<HTMLButtonElement>}
                className="action-button"
                color="submit"
                // disabled={isSubmitting}
                form="pending-booking-initiate-onboarding"
              >
                {t`initiatePage.submit`}
              </Button>
            </Col>
            <Col xs="auto" className="popup-container">
              {!booking ? null : <BookingShareButton />}
            </Col>
            <Col xs="auto">
              <Button color="transparent" onClick={() => hideBookingModal(false)}>
                <CloseIcon fontSize="large" style={{ fill: 'white' }} />
              </Button>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody className="booking-modal-body">
          <div className="booking-header">
            <Row className="align-items-center">
              <Col xs="auto">
                {bookingPublicId
                  ? t('initiatePage.title', { id: `#${bookingPublicId}` })
                  : t('initiatePage.newBooking')}
              </Col>
              <Col xs="auto">
                <div
                  className="booking-status"
                  style={{ backgroundColor: modalBookingStatus?.color ?? DEFAULT_BOOKING_STAGE_COLOR }}
                >
                  {t(modalBookingStatus?.name ?? '')}{' '}
                </div>
              </Col>
            </Row>
          </div>

          {bookingPreloadStatus === 'loading' ? (
            <Container className="booking-loading">
              <Row>
                <Col xs="12" align="center">
                  <CircularProgress size="4rem" sx={{ color: modalBookingStatus?.color }} />
                </Col>
              </Row>
              <Row>
                <Col className="mt-3" xs="12" align="center">
                  <span>
                    <b>{t`calendar:bookingLoading`}</b>
                  </span>
                </Col>
              </Row>
            </Container>
          ) : bookingPreloadStatus === 'error' ? (
            <div className="booking-error">
              <Alert severity="error">
                <AlertTitle>{t`calendar:bookingError`}</AlertTitle>
              </Alert>
            </div>
          ) : null}

          <div
            className={classNames('booking-form', {
              'd-none': bookingPreloadStatus !== 'success' && bookingPreloadStatus !== undefined,
            })}
          >
            <Row className="mt-4">
              <Col>
                <FormText color="required-fields-help-2">{t`requiredFieldsHelp`}</FormText>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </Box>
  );
}

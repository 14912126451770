import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

enum CheckboxState {
  Exclude = 'exclude',
  Include = 'include',
  Ignore = 'ignore',
}

interface BookingsFilterCheckProps {
  color: string;
  label: string;
  value: string;
  iconLabel?: ReactElement;
  onStateChange?: (state: CheckboxState) => void;
  parentState?: CheckboxState;
  setItemsIncludedList: Dispatch<SetStateAction<string[]>>;
  setItemsExcludedList: Dispatch<SetStateAction<string[]>>;
}

export default function BookingsFilterCheck({
  color,
  label,
  iconLabel,
  value,
  onStateChange = () => {},
  parentState = CheckboxState.Ignore,
  setItemsIncludedList,
  setItemsExcludedList,
}: BookingsFilterCheckProps) {
  const [stateCheckbox, setStateCheckbox] = useState<CheckboxState>(CheckboxState.Include);

  useEffect(() => {
    if (parentState === CheckboxState.Include) {
      setStateCheckbox(CheckboxState.Include);
    } else if (parentState === CheckboxState.Exclude) {
      setStateCheckbox(CheckboxState.Exclude);
    } else if (parentState === CheckboxState.Ignore) {
      setStateCheckbox(CheckboxState.Ignore);
    }
  }, [parentState]);

  useEffect(() => {
    if (stateCheckbox === CheckboxState.Include) {
      setItemsIncludedList((prevList) => [...new Set([...prevList, value])]);
      setItemsExcludedList((prevList) => prevList.filter((item) => item !== value));
    } else if (stateCheckbox === CheckboxState.Exclude) {
      setItemsExcludedList((prevList) => [...new Set([...prevList, value])]);
      setItemsIncludedList((prevList) => prevList.filter((item) => item !== value));
    } else {
      setItemsIncludedList((prevList) => prevList.filter((item) => item !== value));
      setItemsExcludedList((prevList) => prevList.filter((item) => item !== value));
    }
  }, [setItemsExcludedList, setItemsIncludedList, stateCheckbox, value]);

  const handleChange = () => {
    let newState: CheckboxState;
    if (stateCheckbox === CheckboxState.Ignore) {
      newState = CheckboxState.Include;
    } else if (stateCheckbox === CheckboxState.Include) {
      newState = CheckboxState.Exclude;
    } else {
      newState = CheckboxState.Ignore;
    }
    setStateCheckbox(newState);
    onStateChange(newState);
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            indeterminateIcon={<CancelIcon />}
            checked={stateCheckbox === CheckboxState.Include}
            indeterminate={stateCheckbox === CheckboxState.Exclude}
            onChange={handleChange}
            value={value}
            sx={{
              color,
              '&.Mui-checked': {
                color,
              },
              '&.MuiCheckbox-indeterminate': {
                color,
              },
            }}
          />
        }
        label={label}
      />
      {iconLabel && iconLabel}
    </Box>
  );
}

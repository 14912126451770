import Calendar from 'src/pages/BookingsPage/Calendar/Calendar';
import { Dispatch, SetStateAction, Ref, useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import BookingsHeader from 'src/pages/BookingsPage/Components/BookingsHeader/BookingsHeader';
import BookingsList from 'src/pages/BookingsPage/List/List';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import BookingAside from 'src/pages/BookingsPage/Components/BookingsAside/BookingAside';
import { Alert, AlertTitle, Box, CircularProgress, useTheme } from '@mui/material';
import { Button, Col, Container, FormText, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Booking, BookingStatus } from 'src/app/types/Entities';
import BookingOnboardingForm from 'src/pages/BookingsPage/Onboarding/Form';
import { DEFAULT_BOOKING_STAGE_COLOR } from 'src/app/helpers/constants';
import BookingShareButton from 'src/components/BookingShareButton/BookingShareButton';
import { useQuery } from '@tanstack/react-query';
import {
  EventsGetParams,
  getEvent,
  getLightEvents,
  getEvents,
  MUTATION_KEYS,
  getLightEventsPaginated,
  getUser,
  getBooking,
} from 'src/app/api';
import { useStateDict } from 'src/app/hooks';
import { useFilterEvents } from 'src/app/hooks/useFilterEvents';
import { mapLightEventToCalendar } from 'src/app/helpers/light_mappers';
import { mapEventToCalendar } from 'src/app/helpers/mappers';
import FullCalendar, { EventInput } from '@fullcalendar/react';
import { isNil } from 'lodash';
import { isNumeric } from 'src/app/helpers/sanitizers';
import classNames from 'classnames';
import { LightBEvent } from 'src/app/api/config/light_entities';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import { DisplayBookingModalFunction } from './localTypes';
import './BookingsPage.scss';

interface BookingsPageProps {
  setEventFiltersProps: Dispatch<SetStateAction<EventsGetParams>>;
}

const translationArray = ['booking', 'calendar'];

export default function BookingsPage({ setEventFiltersProps }: BookingsPageProps) {
  const { t } = useTranslation(translationArray);
  const navigate = useNavigate();
  const theme = useTheme();

  // Modal
  const [searchParams] = useSearchParams();
  const saveRef = useRef<HTMLButtonElement>();
  const calendarRef = useRef<FullCalendar>(null);

  const paramEventId = searchParams.get('eventId');
  const preloadEventId = isNumeric(paramEventId) ? Number(paramEventId) : undefined;

  const { search } = useLocation();
  const part = search.split('&end_at__lte=');
  const startSection = part[0];
  const innerParts = startSection.split('?start_at__gte=');
  const startUrl = innerParts[1];
  const [values, setValues] = useStateDict<EventsGetParams>({
    start_at: startUrl,
    end_at: part[1],
  });

  // Calendar Query
  const [eventFilters, setEventFilters] = useFilterEvents();

  const { data: eventsLight, isLoading } = useQuery(
    [MUTATION_KEYS.EVENTS_LIGHT, eventFilters, startUrl],
    () => getLightEvents(values),
    {
      enabled: !isNil(startUrl),
      onSuccess: () => {
        if (values) {
          // Go to date
          const calendarApi = calendarRef?.current?.getApi();
          if (!calendarApi) {
            console.error("There was an error moving to the event's date in the calendar");
            return;
          }
          calendarApi.gotoDate(startUrl);
        }
      },
    },
  );

  const {
    data: eventsHeavy,
    refetch: refetchEvents,
    isLoading: isLoadingHeavy,
  } = useQuery([MUTATION_KEYS.EVENTS, eventFilters, startUrl], () => getEvents(values), {
    enabled: !isNil(startUrl),
    onSuccess: () => {
      if (values) {
        // Go to date
        const calendarApi = calendarRef?.current?.getApi();
        if (!calendarApi) {
          console.error("There was an error moving to the event's date in the calendar");
          return;
        }
        calendarApi.gotoDate(startUrl);
      }
    },
  });

  const [calendarEvents, setCalendarEvents] = useState<EventInput[]>([]);
  const [isHeavyLoaded, setIsHeavyLoaded] = useState(false);

  useEffect(() => {
    setEventFiltersProps(eventFilters);
    if (isHeavyLoaded && eventsHeavy) {
      setCalendarEvents(eventsHeavy.map(mapEventToCalendar));
    } else if (!isLoading && eventsLight) {
      setCalendarEvents(eventsLight.map(mapLightEventToCalendar));
    }
  }, [isHeavyLoaded, eventsHeavy, isLoading, eventsLight, setEventFiltersProps, eventFilters]);

  useEffect(() => {
    if (!isLoadingHeavy && eventsHeavy) {
      setIsHeavyLoaded(true);
    }
  }, [isLoadingHeavy, eventsHeavy]);

  // Pagination
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  // List Query
  const [itemsIncludedList, setItemsIncludedList] = useState<string[]>([]);
  const [itemsExcludedList, setItemsExcludedList] = useState<string[]>([]);

  const [eventFiltersList, setEventFiltersList] = useStateDict<EventsGetParams>({
    page: paginationModel.page + 1,
    page_size: paginationModel.pageSize,
    items_included: itemsIncludedList,
    items_excluded: itemsExcludedList,
    field_to_sort: 'start_at',
    order_to_sort: 'desc',
  });
  const [eventFiltersListHeavy, setEventFiltersListHeavy] = useStateDict<EventsGetParams>({
    page: paginationModel.page + 1,
    page_size: paginationModel.pageSize,
    field_to_sort: 'start_at',
    order_to_sort: 'desc',
  });

  const handleSortModelChange = (sortModel: GridSortModel) => {
    let fieldToSort = '';
    switch (sortModel[0]?.field) {
      case 'provider':
        fieldToSort = 'booking__services__provider__user__first_name';
        break;
      case 'start_date':
        fieldToSort = 'start_at';
        break;
      case 'public_id':
        fieldToSort = 'booking__public_id';
        break;
      case 'patient':
        fieldToSort = 'affiliates__recipient__user__first_name';
        break;
      default:
        fieldToSort = 'booking__companies__name';
    }
    // Here you save the data you need from the sort model
    setEventFiltersList({ ...eventFiltersList, field_to_sort: fieldToSort, order_to_sort: sortModel[0].sort });
    setEventFiltersListHeavy({
      ...eventFiltersListHeavy,
      field_to_sort: fieldToSort,
      order_to_sort: sortModel[0].sort,
    });
  };

  const onFilterModelChange = (model: GridFilterModel) => {
    const filters = model.items.reduce(
      (acc, item) => ({
        ...acc,
        [item.field]: {
          value: item.value,
          operator: item.operator,
        },
      }),
      {},
    );
    setEventFiltersList({ ...eventFiltersList, ...filters });
    setEventFiltersListHeavy({ ...eventFiltersListHeavy, ...filters });
  };

  const {
    data: eventsListLightData,
    isLoading: isLoadingListLightData,
    refetch: refetchListLight,
  } = useQuery([MUTATION_KEYS.EVENTS_LIGHT, eventFiltersList], () => getLightEventsPaginated(eventFiltersList), {
    refetchOnWindowFocus: false,
    refetchInterval: 30000,
  });

  useEffect(() => {
    setEventFiltersList({
      ...eventFiltersList,
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
      items_included: itemsIncludedList,
      items_excluded: itemsExcludedList,
    });
    setEventFiltersListHeavy({
      ...eventFiltersListHeavy,
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel, setEventFiltersList, setEventFiltersListHeavy, itemsIncludedList, itemsExcludedList]);

  const [filteredEvents, setFilteredEvents] = useState<LightBEvent[]>([]);

  useEffect(() => {
    refetchListLight();
  }, [eventFiltersList, refetchListLight]);

  useEffect(() => {
    if (!isLoadingListLightData && eventsListLightData) {
      setFilteredEvents(eventsListLightData.results ?? []);
    }
  }, [isLoadingListLightData, eventsListLightData]);

  const [rowCountState, setRowCountState] = useState(eventsListLightData?.count || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      eventsListLightData?.count !== undefined ? eventsListLightData.count : prevRowCountState,
    );
  }, [eventsListLightData?.count, setRowCountState]);

  const [showCalendar, setShowCalendar] = useState(false);

  // Handles

  const handleToggleCalendar = () => {
    setShowCalendar(true);
    navigate(`/bookings/calendar/?start_at__gte=${eventFilters.start_at}&end_at__lte=${eventFilters.end_at}`);
  };

  useEffect(() => {
    const currentPath = `${window.location.pathname}?start_at__gte=${eventFilters.start_at}&end_at__lte=${eventFilters.end_at}`;
    setShowCalendar(
      currentPath === `/bookings/calendar/?start_at__gte=${eventFilters.start_at}&end_at__lte=${eventFilters.end_at}`,
    );
  }, [eventFilters.end_at, eventFilters.start_at, navigate]);

  const handleToggleList = () => {
    setShowCalendar(false);
    navigate('/bookings/list');
    refetchListLight();
  };

  // State data
  const [booking, setBooking] = useState<Booking | null>(null);
  const [bookingPublicId, setBookingPublicId] = useState<string | null>(null);
  const [eventStartDate, setEventStartDate] = useState<Date | undefined>();
  const [eventEndDate, setEventEndDate] = useState<Date | undefined>();
  const [modalBookingStatus, setModalBookingStatus] = useState<BookingStatus | undefined>();
  const [bookingPreloadStatus, setBookingPreloadStatus] = useState<'loading' | 'success' | 'error' | undefined>();
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [checkIsDirty, setCheckIsDirty] = useState<() => { isDirty: boolean; fieldsDirty: string[] }>(() => () => ({
    isDirty: false,
    fieldsDirty: [],
  }));

  useEffect(() => {
    // Function to display a confirmation message
    const handleBeforeUnload = (ev: BeforeUnloadEvent) => {
      const { isDirty } = checkIsDirty();
      if (isDirty) {
        ev.preventDefault();
        // eslint-disable-next-line no-param-reassign
        ev.returnValue = t`errors.unsavedChanges`;
      }
    };

    // Add the event listener when component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [checkIsDirty, t]);

  const hideBookingModal = async (isSaving: boolean) => {
    const { isDirty, fieldsDirty } = checkIsDirty();

    let canClose = true;
    let swalResponse;
    const text = t`fieldsWithChanges` + fieldsDirty.map((field) => t(`fields.${field}`)).join(', ');

    if (isDirty && !isSaving) {
      canClose = false;

      swalResponse = await Swal.fire({
        title: t`errors.unsavedChanges`,
        icon: 'warning',
        text,
        confirmButtonText: t`actions.saveAndClose`,
        confirmButtonColor: '#00a2b8',
        showDenyButton: true,
        denyButtonText: t`actions.keepEditing`,
        denyButtonColor: '#6e7881',
        showCancelButton: true,
        cancelButtonText: t`actions.discard`,
        cancelButtonColor: '#dc3741',
        allowOutsideClick: true,
        width: '40em',
        customClass: {
          confirmButton: 'btn action-button',
          denyButton: 'btn action-button',
          cancelButton: 'btn action-button',
        },
      }).then((result) => result);
    }

    if (swalResponse?.isDenied) {
      return;
    }

    if (swalResponse?.isConfirmed) {
      saveRef.current?.click();
    }

    if (swalResponse?.isDismissed || canClose) {
      setShowBookingModal(false);
      setBooking(null);
      setBookingPublicId(null);
      setEventStartDate(undefined);
      setEventEndDate(undefined);
      setModalBookingStatus(undefined);
      setBookingPreloadStatus(undefined);
    }
    if (showCalendar) {
      navigate(`/bookings/calendar/?start_at__gte=${eventFilters.start_at}&end_at__lte=${eventFilters.end_at}`);
    } else {
      navigate('/bookings/list');
    }
  };

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const displayBookingModal: DisplayBookingModalFunction = (
    publicId: string | null,
    startDate: Date | undefined,
    endDate: Date | undefined,
    bookingStatus: BookingStatus | undefined,
    preloadStatus: 'loading' | 'success' | 'error' | undefined,
    bookingToDisplay: Booking | null = null,
  ) => {
    setBookingPublicId(publicId);
    setEventStartDate(startDate);
    setEventEndDate(endDate);
    setModalBookingStatus(bookingStatus);
    setShowBookingModal(true);
    setBooking(bookingToDisplay);
    if (preloadStatus === undefined) {
      setBookingPreloadStatus('loading');
    }
  };

  useQuery([MUTATION_KEYS.EVENTS, preloadEventId], () => getEvent(preloadEventId!), {
    enabled: !isNil(preloadEventId) && !Number.isNaN(preloadEventId),
    onSuccess: (event) => {
      if (showCalendar) {
        // Go to date
        const calendarApi = calendarRef?.current?.getApi();
        if (!calendarApi) {
          console.error("There was an error moving to the event's date in the calendar");
          return;
        }
        calendarApi.gotoDate(event.start_at);
      }

      // Display modal
      if (!event.booking.id) {
        console.warn('There was an error displaying the booking onboarding modal');
        return;
      }
      getBooking(event.booking.id)
        .then((bookingForDisplay) => {
          displayBookingModal(
            bookingForDisplay.public_id,
            event.start_at,
            event.end_at,
            bookingForDisplay.cCurrentStatus,
            bookingPreloadStatus,
            bookingForDisplay as Booking,
          );

          return bookingForDisplay;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // Prevent spamming the modal to the user
    refetchInterval: 0,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    if (paramEventId === null) {
      setShowBookingModal(false);
    }
  }, [setShowBookingModal, paramEventId]);

  // Bookings Page
  const [handlePrevClick, setHandlePrevClick] = useState<() => void>();
  const [handleNextClick, setHandleNextClick] = useState<() => void>();
  const [handleTodayClick, setHandleTodayClick] = useState<() => void>();
  const [handleViewChange, setHandleViewChange] = useState<() => void>();

  const handleRefetch = () => {
    hideBookingModal(true);
    if (showCalendar) refetchEvents();
    else refetchListLight();
  };

  // Group Booking
  const getIsGroupBooking = async (): Promise<SweetAlertResult<any>> => {
    return Swal.fire({
      title: 'Is this a Group Booking?',
      text: 'Group bookings are bookings with multiple patients.',
      icon: 'question',
      showConfirmButton: true,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#00a2b8',
      denyButtonText: 'No',
      denyButtonColor: '#6e7881',
      cancelButtonText: t`actions.cancel`,
      cancelButtonColor: '#dc3741',
      allowOutsideClick: true,
      width: '40em',
      customClass: {
        confirmButton: 'btn action-button',
        denyButton: 'btn action-button',
        cancelButton: 'btn action-button',
      },
    });
  };

  const [groupBooking, setGroupBooking] = useState(false);

  const handleCreateClick = async () => {
    const isGroupBooking = await getIsGroupBooking();
    if (isGroupBooking.isConfirmed) {
      setGroupBooking(true);
    } else if (isGroupBooking.isDenied) {
      setGroupBooking(false);
    } else if (isGroupBooking.isDismissed) {
      return undefined;
    }

    displayBookingModal(
      null,
      undefined,
      undefined,
      {
        name: 'pending',
        color: '#ff7976',
        step: 'initiate',
      },
      'success',
      null,
    );
    return undefined;
  };

  useEffect(() => {
    if (!booking?.id) {
      if (groupBooking) {
        setGroupBooking(groupBooking);
      } else {
        setGroupBooking(groupBooking);
      }
    } else if (booking?.group_booking) {
      setGroupBooking(true);
    } else {
      setGroupBooking(false);
    }
  }, [booking, groupBooking]);

  // Created By
  const [creatorRefetchEnabled, setCreatorRefetchEnabled] = useState<boolean>(true);

  const { data: creator } = useQuery(
    [MUTATION_KEYS.USERS, booking?.created_by],
    () => (booking?.created_by ? getUser(booking?.created_by) : undefined),
    {
      enabled: creatorRefetchEnabled,
    },
  );

  useEffect(() => {
    if (creator) {
      setCreatorRefetchEnabled(false);
    }
  }, [creator]);

  const formPreSubmit = () => {
    setIsSubmitting(true);
    return true;
  };

  const formPostSubmit = () => {
    if (showCalendar) {
      navigate(`/bookings/calendar/?start_at__gte=${eventFilters.start_at}&end_at__lte=${eventFilters.end_at}`);
    } else {
      navigate('/bookings/list');
    }

    setTimeout(() => {
      setIsSubmitting(false);
    }, 30000);
  };

  // Open Drawer
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  return (
    <Box className="w-100 h-100 mw-100 p-0 container" sx={{ display: 'flex' }}>
      <BookingAside
        drawerOpen={drawerOpen}
        setItemsIncludedList={setItemsIncludedList}
        setItemsExcludedList={setItemsExcludedList}
      />
      <Box
        component="main"
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginLeft: drawerOpen ? 0 : '-240px',
          minHeight: 0,
          minWidth: 0,
          transition: drawerOpen
            ? theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              })
            : theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
        }}
      >
        <BookingsHeader
          onPrevClick={handlePrevClick}
          onTodayClick={handleTodayClick}
          onNextClick={handleNextClick}
          onViewChange={handleViewChange}
          onToggleCalendar={handleToggleCalendar}
          onToggleList={handleToggleList}
          onCreate={handleCreateClick}
          eventFilters={eventFilters}
          handleDrawerToggle={handleDrawerToggle}
          drawerOpen={drawerOpen}
        />

        {showCalendar ? (
          <Calendar
            setHandlePrevClick={setHandlePrevClick}
            setHandleNextClick={setHandleNextClick}
            setHandleTodayClick={setHandleTodayClick}
            setHandleViewChange={setHandleViewChange}
            displayBookingModal={displayBookingModal}
            calendarRef={calendarRef}
            setEventFilters={setEventFilters}
            isLoading={isLoading}
            calendarEvents={calendarEvents}
            paramEventId={paramEventId}
            fetchUrl={eventFilters}
            urlValues={values}
            setUrlValues={setValues}
            getIsGroupBooking={getIsGroupBooking}
            setGroupBooking={setGroupBooking}
          />
        ) : (
          <BookingsList
            filteredEvents={filteredEvents}
            displayBookingModal={displayBookingModal}
            bookingPreloadStatus={bookingPreloadStatus}
            paramEventId={paramEventId}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            rowCount={rowCountState}
            handleSortModelChange={handleSortModelChange}
            handleFilterModelChange={onFilterModelChange}
            isLoading={isLoadingListLightData}
          />
        )}
      </Box>

      <Modal
        fullscreen
        className="booking-modal"
        isOpen={showBookingModal}
        toggle={() => hideBookingModal(true)}
        keyboard={false}
      >
        <ModalHeader className="booking-modal-header">
          <Row className="align-items-center">
            <Col>
              <Button
                innerRef={saveRef as Ref<HTMLButtonElement>}
                className="action-button"
                color="submit"
                disabled={isSubmitting}
                form="pending-booking-initiate-onboarding"
              >
                {isSubmitting ? (
                  <Spinner className="me-2" size="sm" type="border" color="primary" />
                ) : (
                  t`initiatePage.submit`
                )}
              </Button>
            </Col>
            <Col xs="auto" className="popup-container">
              {!booking ? null : <BookingShareButton />}
            </Col>
            <Col xs="auto">
              <Button color="transparent" disabled={isSubmitting} onClick={() => hideBookingModal(false)}>
                <CloseIcon fontSize="large" style={{ fill: 'white' }} />
              </Button>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody className="booking-modal-body">
          <div className="booking-header">
            <Row className="align-items-center">
              <Col xs="auto">
                {bookingPublicId
                  ? t('initiatePage.title', {
                      id: `#${bookingPublicId}`,
                    })
                  : t('initiatePage.newBooking')}
              </Col>
              <Col xs="auto">
                <div
                  className="booking-status"
                  style={{ backgroundColor: modalBookingStatus?.color ?? DEFAULT_BOOKING_STAGE_COLOR }}
                >
                  {t(modalBookingStatus?.name ?? '')}{' '}
                </div>
              </Col>
              {groupBooking && (
                <Col xs="auto">
                  <div className="booking-status" style={{ backgroundColor: '#6e7881' }}>
                    {t('Group')}{' '}
                  </div>
                </Col>
              )}
              <Col xs="auto">
                {bookingPublicId && creator?.id ? (
                  <>
                    <span className="created-by-text">{t('initiatePage.createdBy')}</span>
                    <span className="created-by-name">
                      {' '}
                      {creator.first_name} {creator.last_name}
                    </span>
                  </>
                ) : null}
              </Col>
            </Row>
          </div>

          {bookingPreloadStatus === 'loading' ? (
            <Container className="booking-loading">
              <Row>
                <Col xs="12" align="center">
                  <CircularProgress size="4rem" sx={{ color: modalBookingStatus?.color }} />
                </Col>
              </Row>
              <Row>
                <Col className="mt-3" xs="12" align="center">
                  <span>
                    <b>{t`calendar:bookingLoading`}</b>
                  </span>
                </Col>
              </Row>
            </Container>
          ) : bookingPreloadStatus === 'error' ? (
            <div className="booking-error">
              <Alert severity="error">
                <AlertTitle>{t`calendar:bookingError`}</AlertTitle>
              </Alert>
            </div>
          ) : null}

          <div
            className={classNames('booking-form', {
              'd-none': bookingPreloadStatus !== 'success' && bookingPreloadStatus !== undefined,
            })}
          >
            <BookingOnboardingForm
              id="pending-booking-initiate-onboarding"
              key={booking?.id}
              preloadBooking={booking?.created_at ? booking : undefined}
              preloadBookingId={booking?.id}
              preloadStatus={bookingPreloadStatus}
              setPreloadStatus={setBookingPreloadStatus}
              initialEventStartDate={eventStartDate}
              initialEventEndDate={eventEndDate}
              setBookingStatus={setModalBookingStatus}
              setCheckIsDirty={setCheckIsDirty}
              preSubmit={formPreSubmit}
              onSubmitSuccess={handleRefetch}
              postSubmit={formPostSubmit}
              groupBooking={groupBooking}
            />

            <Row className="mt-4">
              <Col>
                <FormText color="required-fields-help-2">{t`requiredFieldsHelp`}</FormText>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </Box>
  );
}

import { AxiosError } from 'axios';
import { DatabaseId, Errors } from '../types/DataStructures';
import { AffiliationsGetParams } from './affiliations';
import { AgentsGetParams } from './agents';
import { BookingsGetParams } from './bookings';
import { apiClient } from './config/clients';
import {
  EVENTS_ENDPOINT,
  EVENTS_LIGHT_ENDPOINT,
  EMAIL_ENDPOINT,
} from './config/endpoints';
import { ApiQuery, applyLookup, BUSINESS_NAME, businessParam, LookupsMap, pathParam } from './config/helpers';
import { RequestersGetParams } from './requesters';
import { CompaniesGetParams } from './companies';
import { PayersGetParams } from './payers';
import { CaseType } from '../helpers/enum';
import { sanitizeLightEvent, sanitizeLightShallowEvent } from './config/light_sanitizers';
import { LightBEvent } from './config/light_entities';
import hydrateLightEvent from './config/light_hydrators';
import { BEvent } from '../types/Entities';
import { sanitizeEvent } from './config/sanitizers';
import { hydrateEvent } from './config/hydrators';

// GET
export interface EventsGetApiParams {
  id?: DatabaseId;
  _include_booking?: boolean;
  affiliates?: AffiliationsGetParams;
  agents?: AgentsGetParams;
  booking?: Omit<BookingsGetParams, 'events'>;
  payer?: PayersGetParams;
  payer_company?: CompaniesGetParams;
  requester?: RequestersGetParams;
  location?: Location;
  meeting_url?: string;
  start_at?: string;
  end_at?: string;

  // Claim
  claim_number?: string;
  date_of_injury?: string;
  diagnosis?: string;
  payer_company_type?: string;

  // Pagination
  page?: number;
  page_size?: number;

  // Sorting
  field_to_sort?: string;
  order_to_sort?: string | null;

  // Filters
  items_included?: string[];
  items_excluded?: string[];
  recipient_id?: DatabaseId;
  agent_id?: DatabaseId;

  // Modes
  report?: Boolean;
}

export type EventsGetParams = Omit<EventsGetApiParams, '_include_booking'>;

export const DEFAULT_EVENTS_LOOKUPS: LookupsMap<EventsGetParams> = {};

export const getEvents: ApiQuery<EventsGetParams, BEvent[]> = (params, lookups = DEFAULT_EVENTS_LOOKUPS) => {
  const queryParams: Record<string, any> = applyLookup<EventsGetApiParams>({ ...params }, lookups);
  const searchParams = new URLSearchParams();

  Object.keys(queryParams).forEach((key) => {
    const value = queryParams[key];
    if (Array.isArray(value)) {
      value.forEach((val) => searchParams.append(key, val));
    } else {
      searchParams.append(key, value);
    }
  });

  const url = `${businessParam(EVENTS_ENDPOINT)}?${searchParams.toString()}`;

  return apiClient
    .get<any[]>(url)
    .then((res) => {
      let EventData = res.data;
      if (EventData.length > 0) {
        EventData = EventData.map(sanitizeEvent);
      }
      return EventData;
    })
    .then((dehydratedEvents) => {
      let dehydratedEventsOrSingle = dehydratedEvents;
      if (dehydratedEventsOrSingle.length > 0) {
        dehydratedEventsOrSingle = dehydratedEventsOrSingle.map(hydrateEvent);
      }

      return Promise.all(dehydratedEventsOrSingle);
    });
};

export const getEventsPaginated: ApiQuery<
  EventsGetParams,
  { count: number; next: string | null; previous: string | null; results: BEvent[] }
> = (params, lookups = DEFAULT_EVENTS_LOOKUPS) =>
  apiClient
    .get<any>(businessParam(EVENTS_ENDPOINT), {
      params: applyLookup<EventsGetApiParams>({ ...params }, lookups),
    })
    .then((res) => res.data)
    .then((data) => ({
      ...data,
      results: data.results.map(sanitizeEvent),
    }))
    .then(async (data) => ({
      ...data,
      results: await Promise.all(data.results.map(hydrateEvent)),
    }));

export const getLightEvents: ApiQuery<EventsGetParams, LightBEvent[]> = (params, lookups = DEFAULT_EVENTS_LOOKUPS) =>
  apiClient
    .get<any[]>(businessParam(EVENTS_LIGHT_ENDPOINT), {
      params: applyLookup<EventsGetApiParams>({ ...params }, lookups),
    })
    .then((res) => res.data.map(sanitizeLightEvent))
    .then((dehydratedLightEvents) => Promise.all(dehydratedLightEvents.map(hydrateLightEvent)));

export const getLightEventsPaginated: ApiQuery<
  EventsGetParams,
  { count: number; next: string | null; previous: string | null; results: LightBEvent[] }
> = (params, lookups = DEFAULT_EVENTS_LOOKUPS) =>
  apiClient
    .get<any>(businessParam(EVENTS_LIGHT_ENDPOINT), {
      params: applyLookup<EventsGetApiParams>({ ...params }, lookups),
    })
    .then((res) => res.data)
    .then((data) => ({
      ...data,
      results: data.results.map(sanitizeEvent),
    }))
    .then(async (data) => ({
      ...data,
      results: await Promise.all(data.results.map(hydrateLightEvent)),
    }));

export const getLightEventsNoBooking: ApiQuery<EventsGetParams, LightBEvent[]> = (
  params,
  lookups = DEFAULT_EVENTS_LOOKUPS,
) =>
  apiClient
    .get<any[]>(businessParam(EVENTS_LIGHT_ENDPOINT), {
      params: applyLookup<EventsGetApiParams>({ ...params }, lookups),
    })
    .then((res) => res.data.map(sanitizeLightShallowEvent));

export const getEvent = (eventId: DatabaseId): Promise<BEvent> =>
  apiClient
    .get(pathParam(EVENTS_ENDPOINT, eventId))
    .then((res) => sanitizeEvent(res.data))
    .then(hydrateEvent);

export const getEventReports: ApiQuery<EventsGetParams, any[]> = () =>
  apiClient
    .get<any[]>(businessParam(EVENTS_ENDPOINT), {
      params: {
        items_excluded: ['marked_as_invoiced'],
        items_included: ['delivered'],
        report: true,
      },
    })
    .then((res) => res.data);

// POST
export interface EventsPostPayloadCaseFields {
  claim_number?: string | null;
  date_of_injury?: string | null;
  diagnosis?: string | null;
  claim_insurance_id?: DatabaseId | null;
  claim_adjuster_id?: DatabaseId | null;
  claim_lawfirm_id?: DatabaseId | null;
  claim_lawyer_id?: DatabaseId | null;
  case_type?: CaseType | null;
}

export interface EventsPostPayload extends EventsPostPayloadCaseFields {
  id?: DatabaseId;
  _deleted?: boolean;
  affiliates?: DatabaseId[];
  agents?: DatabaseId[];
  authorizations?: DatabaseId[];
  booking: DatabaseId;
  requester: DatabaseId;
  location?: DatabaseId;
  meeting_url?: string;
  start_at: string;
  end_at: string;
  description: string;
  arrive_at: string;
  mark_as_invoiced?: boolean;

  payer_company_type?: string;
  payer_company?: DatabaseId | null;
  payer?: DatabaseId | null;
}
export type EventsPostResponse = DatabaseId;
export type EventsPostError = Errors<EventsPostPayload>;
export const createEvent = (payload: EventsPostPayload) =>
  apiClient
    .post<EventsPostResponse>(businessParam(EVENTS_ENDPOINT), payload)
    .then((res) => res.data)
    .catch((err: AxiosError<EventsPostError>) => Promise.reject(err.response?.data));

export interface EmailPayload {
  subject: string;
  body: string;
  recipient: string | undefined;
}

export type ErrAxios = AxiosError<EventsPostError>;
export const sendEmail = (payload: EmailPayload) => {
  return new Promise((resolve, reject) => {
    apiClient
      .post<EmailPayload>(EMAIL_ENDPOINT, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err: AxiosError<EventsPostError>) => {
        reject(err.response?.data);
      });
  });
};
// PUT
export type EventsPutPayload = EventsPostPayload;
export type EventsPutResponse = unknown;
export type EventsPutError = Errors<EventsPutPayload>;
export const updateEvent = (eventId: DatabaseId, payload: EventsPutPayload) =>
  apiClient
    .put<EventsPutResponse>(pathParam(EVENTS_ENDPOINT, eventId), {
      ...payload,
      _business: BUSINESS_NAME,
    })
    .catch((err: AxiosError<EventsPutError>) => Promise.reject(err.response?.data));

// PATCH (bulk partial update)
export type EventsPatchPayload = Partial<EventsPutPayload>;
export type EventsPatchResponse = unknown;
export type EventsPatchError = Errors<EventsPatchPayload>;
export const patchEvents = (query: EventsGetParams, payload: EventsPatchPayload) =>
  apiClient
    .patch<EventsPatchResponse>(businessParam(EVENTS_ENDPOINT), {
      ...payload,
      _business: BUSINESS_NAME,
      _query: query,
    })
    .catch((err: AxiosError<EventsPatchError>) => Promise.reject(err.response?.data));

// DELETE
export type EventsDeleteResponse = unknown;
export type EventsDeleteError = string;
export const deleteEvent = (eventId: DatabaseId) =>
  apiClient
    .delete<EventsDeleteResponse>(pathParam(EVENTS_ENDPOINT, eventId))
    .catch((err: AxiosError<EventsDeleteError>) => Promise.reject(err.response?.data));

import { v4 as uuidv4 } from 'uuid';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createUserComplex,
  getCompanyWithRoles,
  MUTATION_KEYS,
  updateUserComplex,
  UsersPostError,
  UsersPostErrorComplex,
  UsersPostPayloadComplex,
  UsersPostResponseComplex,
  UsersPutError,
  UsersPutErrorComplex,
  UsersPutPayloadComplex,
  UsersPutResponse,
} from 'src/app/api';
import { SUFFIXES } from 'src/app/helpers/collections';
import {
  AGENCY_AGENT_ROLES,
  AgentRole,
  BUTTON_STATE,
  CLINIC_AGENT_ROLES,
  INSURANCE_AGENT_ROLES,
  PayerType,
  REQUESTER_TYPES,
  RequesterType,
  TITLES,
} from 'src/app/helpers/enum';
import {
  buildUsername,
  flattenContacts,
  mapArrayForSelect,
  mapObjectForSelect,
  unflattenContacts,
  userToString,
} from 'src/app/helpers/mappers';
import { useForm, useStateArray, useStateDict } from 'src/app/hooks';
import { Clinic, Company, FlatContact, Requester } from 'src/app/types/Entities';
import { useMutation, useQuery } from '@tanstack/react-query';
import { findInOptions, stringifyDate } from 'src/app/helpers/manipulation';
import { toast } from 'react-toastify';
import FormExpandableField from 'src/components/Form/FormExpandableField';
import { Button, Col, FormText, Row } from 'reactstrap';
import { ErrorsAlert } from 'src/components/ErrorsAlert';
import { FormInput } from 'src/components/Form/FormInput';
import FormWrapper from 'src/components/Form/FormWrapper';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import { TextField } from '@mui/material';
import FormSelectCreatable from 'src/components/Form/FormSelectCreatable/FormSelectCreatable';
import { FormSelectOptions } from '../../../../app/types/Components';
import FlatContactList from './FlatContactList/FlatContactList';

export interface RequesterFieldProps {
  showRequesterField: boolean;
  setShowRequesterField: (display: boolean) => void;
  requester: Requester | null;
  setRequester: (requester: Requester | null) => void;
  requesterCompanySource: RequesterType | null;
  setRequesterCompanySource: (companyType: RequesterType | null) => void;
  clinic: Clinic | null;
  payerCompany: Company | null;
  payerType: PayerType | null;
  onUpdateSuccess?: (requester?: Requester) => void;
}

const translationArray = ['booking', 'forms', 'languages', 'collections'];
const formPrefix = 'requester';

function RequesterField(props: RequesterFieldProps) {
  const {
    showRequesterField,
    setShowRequesterField,
    requester,
    setRequester,
    requesterCompanySource,
    setRequesterCompanySource,
    clinic,
    payerCompany,
    payerType,
    onUpdateSuccess,
  } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Form data
  const [errors, setErrors, updateErrors] = useStateDict<
    UsersPostErrorComplex & UsersPutErrorComplex & { requesterRole?: string[] }
  >({});
  const [createMode, setCreateMode] = useState<boolean>(false);

  const [title, setTitle] = useState<string | undefined>();
  const titleOptions = mapObjectForSelect(TITLES, (titleKey) => t(`collections:titles.${titleKey}`));

  const [requesterRole, setRequesterRole] = useState<string | undefined>();

  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [suffix, setSuffix] = useState<string | undefined>();
  const suffixOptions = mapArrayForSelect(SUFFIXES, (suffixKey) => t(`collections:suffixes.${suffixKey}`));
  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>();

  const {
    values: flatContacts,
    set: setFlatContacts,
    push: pushFlatContact,
    replaceAt: replaceFlatContactAt,
    removeAt: removeFlatContactAt,
  } = useStateArray<FlatContact>([]);

  const addNewFlatContact = () =>
    pushFlatContact({
      type: 'email',
      data: {},
      context: 'work',
      uuid: uuidv4(),
    });

  const prefillForm = () => {
    setTitle(requester?.title);
    setFirstName(requester?.first_name);
    setLastName(requester?.last_name);
    setDateOfBirth(requester?.date_of_birth);
    setSuffix(requester?.suffix);
    setFlatContacts(flattenContacts(requester?.contacts) ?? []);
  };

  useEffect(() => {
    prefillForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requester, setFlatContacts]);

  useEffect(() => {
    if (flatContacts.length <= 0) {
      addNewFlatContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatContacts.length]);

  const requesterRoleOptions = (): FormSelectOptions<AgentRole> => {
    if (requesterCompanySource === 'clinic') {
      return mapObjectForSelect(CLINIC_AGENT_ROLES, (_, v) => t(`collections:agentRoles.${v}`));
    }
    if (requesterCompanySource === 'insurance') {
      return mapObjectForSelect(INSURANCE_AGENT_ROLES, (_, v) => t(`collections:agentRoles.${v}`));
    }
    if (requesterCompanySource === 'agency') {
      return mapObjectForSelect(AGENCY_AGENT_ROLES, (_, v) => t(`collections:agentRoles.${v}`));
    }
    return [];
  };

  // Query
  const requesterTypeOptions = mapObjectForSelect(REQUESTER_TYPES, (k) => t(`collections:requesterTypes.${k}`)).filter(
    ({ value }) => value === 'clinic' || value === payerType || value === 'patient',
  );

  const companyId = useMemo(() => {
    if (requesterCompanySource === 'clinic') {
      return clinic?.id;
    }
    if (requesterCompanySource === 'insurance' && payerType === 'insurance') {
      return payerCompany?.id ?? undefined;
    }
    if (requesterCompanySource === 'agency' && payerType === 'agency') {
      return payerCompany?.id ?? undefined;
    }
    return undefined;
  }, [clinic?.id, payerCompany?.id, payerType, requesterCompanySource]);

  const {
    data: queryCompany,
    isLoading: isQueryCompanyLoading,
    refetch: refetchCompany,
  } = useQuery(
    [MUTATION_KEYS.COMPANIES, companyId],
    () => (companyId ? getCompanyWithRoles(companyId) : Promise.reject()),
    { refetchOnWindowFocus: false },
  );

  // On saving
  const { mutateAsync: mutateAsyncCreateUserComplex } = useMutation<
    UsersPostResponseComplex,
    UsersPostErrorComplex,
    UsersPostPayloadComplex
  >(createUserComplex);

  const { mutateAsync: mutateAsyncUpdateUserComplex } = useMutation<
    UsersPutResponse,
    UsersPutError,
    Parameters<typeof updateUserComplex>
  >((args) => updateUserComplex(...args));

  // On save functions
  // Validate
  const validate = () => {
    let isValid = true;
    const validationErrors = [];

    if (!companyId) {
      validationErrors.push(t`errors.noRequesterCompanyId`);
      isValid = false;
    }

    if (!firstName) {
      updateErrors({ first_name: [t`errors.required`] });
      isValid = false;
    }
    if (!lastName) {
      updateErrors({ last_name: [t`errors.required`] });
      isValid = false;
    }
    if (!requesterRole) {
      updateErrors({ requesterRole: [t`errors.required`] });
      isValid = false;
    }

    if (!createMode && !requester?.id) {
      validationErrors.push(t`errors.expectedRequesterId`);
      isValid = false;
    }

    if (validationErrors.length !== 0) {
      updateErrors({ validation_errors: validationErrors });
      isValid = false;
    }

    return isValid;
  };

  // On create
  const createRequester = () => {
    setErrors({});
    if (!validate()) {
      return;
    }

    const payloadEmailFlatContact = flatContacts.find(({ type, data }) => type !== 'email' && data) as Extract<
      FlatContact,
      { type?: 'email' }
    >;
    const payloadUsername = buildUsername(firstName!, lastName!, dateOfBirth!);

    const payload: UsersPostPayloadComplex = {
      contacts: unflattenContacts(flatContacts),
      date_of_birth: stringifyDate(new Date()),
      email: payloadEmailFlatContact?.data.address ?? '',
      first_name: firstName!,
      last_name: lastName!,
      suffix,
      title,
      username: payloadUsername,

      _recipient_data: {},
      _requester_data: {
        companies: [companyId],
      },
      _agent_data: {
        companies: [companyId!],
        role: requesterRole ?? '',
      },
    };

    toast
      .promise(mutateAsyncCreateUserComplex(payload), {
        pending: t`progress.requesterCreate.started` as string,
        success: t`progress.requesterCreate.success` as string,
        error: t`progress.requesterCreate.error` as string,
      })
      .catch((err: UsersPostError) => {
        setErrors(err);
        console.error(`Errors when creating requester "${payloadUsername}"`, err);
        return Promise.reject();
      })
      .then(async (idsCollection) => {
        const createdRequesterId = idsCollection.requester_id;
        return [await refetchCompany(), createdRequesterId] as const;
      })
      .then(([refetchData, createdRequesterId]) => {
        const { data: newCompany } = refetchData;
        const newlyCreatedRequester = newCompany?.requesters?.find((req) => req.id === createdRequesterId);
        setShowRequesterField(false);
        setCreateMode(false);
        onUpdateSuccess?.(newlyCreatedRequester);
      });
  };

  // On update
  const updateRequester = () => {
    setErrors({});
    if (!validate()) {
      return;
    }

    const payload: UsersPutPayloadComplex = {
      contacts: unflattenContacts(flatContacts),
      date_of_birth: dateOfBirth ? stringifyDate(dateOfBirth!) : stringifyDate(new Date()),
      email: requester!.email,
      first_name: firstName!,
      last_name: lastName!,
      suffix,
      title,
      _requester_data: {
        companies: [companyId!],
      },
      _agent_data: {
        companies: [companyId!],
        role: requesterRole ?? '',
      },
    };

    toast
      .promise(mutateAsyncUpdateUserComplex([requester?.user_id!, payload]), {
        pending: t`progress.requesterUpdate.started` as string,
        success: t`progress.requesterUpdate.success` as string,
        error: t`progress.requesterUpdate.error` as string,
      })
      .catch((err) => {
        if (err) setErrors(err);
        console.error('Errors when updating requester', err);
        return Promise.reject();
      })
      .then(() => refetchCompany())
      .then((refetchData) => {
        const { data: newCompany } = refetchData;
        const updatedRequester = newCompany?.requesters?.find((req) => req.id === requester?.id);
        onUpdateSuccess?.(updatedRequester);
      });
  };

  const onSave = () => (createMode ? createRequester() : updateRequester());

  // Display
  const label = <h5>{t`requester`} *</h5>;

  const display = (
    <Row className="gx-2">
      <Col md={12} xl={12} xxl={6}>
        <FormSelect
          id={p`requesterType`}
          options={requesterTypeOptions}
          value={requesterTypeOptions.find((o) => o.value === requesterCompanySource) ?? null}
          onChange={(_event, requesterCompanySourceOption) =>
            setRequesterCompanySource(requesterCompanySourceOption?.value as RequesterType | null)
          }
          renderInput={(params) => <TextField placeholder={t`requesterType`} {...params} />}
        />
      </Col>
      <Col md={12} xl={12} xxl={6}>
        <FormSelectCreatable<Requester>
          fullWidth
          id={p`requester`}
          options={queryCompany?.requesters ?? []}
          value={createMode ? null : requester}
          loading={isQueryCompanyLoading}
          disabled={
            createMode || !requesterCompanySource || requesterCompanySource === REQUESTER_TYPES.PATIENT || !clinic
          }
          clearOnBlur
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                createMode
                  ? t`newRequester`
                  : requesterCompanySource === REQUESTER_TYPES.PATIENT
                  ? t`patientRequester`
                  : t`selectRequester`
              }
            />
          )}
          getCreatedOptionLabel={() => t`tooltip.addRequester`}
          getSelectedOptionLabel={(requesterOption) => userToString(requesterOption)}
          onCreateOption={(newValue) => {
            setShowRequesterField(!createMode);
            setCreateMode(!createMode);

            const [newFirstName, newLastName] = newValue.split(' ', 2);
            setFirstName(newFirstName);
            setLastName(newLastName);
          }}
          onSelectOption={(option) => setRequester(option as Requester)}
          getOptionValue={(option) => option.id}
        />
      </Col>
    </Row>
  );

  const onCancel = () => {
    setShowRequesterField(false);
    setCreateMode(false);
    prefillForm();
  };

  const footer = (
    <Row>
      <Col>
        <Button className="action-button me-4" color="submit" onClick={onSave} block>
          {t`saveRequester`}
        </Button>
      </Col>
      <Col>
        <Button className="action-button" color="cancel" onClick={onCancel} block>
          {t`cancel`}
        </Button>
      </Col>
      <Col>
        <FormText color="required-fields-help">{t`requiredFieldsHelp`}</FormText>
      </Col>
    </Row>
  );

  return (
    <FormExpandableField
      formPrefix={p``}
      isOpen={showRequesterField}
      toggleOpen={setShowRequesterField}
      canClose={!createMode}
      openButtonState={
        !requester || createMode || !requesterCompanySource ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE
      }
      openButtonTooltip={showRequesterField ? t`tooltip.hideRequester` : t`tooltip.viewRequester`}
      label={label}
      display={display}
      footer={footer}
      onCancel={onCancel}
    >
      <Row className="gx-2">
        <Col md={12} xl={12} xxl={2}>
          <FormSelect
            id={p`title`}
            options={titleOptions}
            value={titleOptions.find((o) => o.value === title)}
            onChange={(_event, titleOption) => setTitle(titleOption.value)}
            renderInput={(params) => <TextField placeholder={t`title`} {...params} />}
            disableClearable
          />
        </Col>
        <Col md={12} xl={6} xxl={4}>
          <FormInput
            label={`${t`firstName`} *`}
            value={firstName ?? ''}
            onChange={(e) => setFirstName(e.target.value)}
            errors={errors.first_name}
          />
        </Col>
        <Col md={12} xl={6} xxl={4}>
          <FormInput
            label={`${t`lastName`} *`}
            value={lastName ?? ''}
            onChange={(e) => setLastName(e.target.value)}
            errors={errors.last_name}
          />
        </Col>
        <Col md={12} xl={12} xxl={2}>
          <FormSelect
            id={p`suffix`}
            options={suffixOptions}
            value={suffixOptions.find((o) => o.value === suffix)}
            onChange={(_event, suffixOption) => setSuffix(suffixOption.value)}
            renderInput={(params) => <TextField placeholder={t`suffix`} {...params} />}
            disableClearable
          />
        </Col>
        <Col md={12} xl={12} xxl={6}>
          <FormSelect
            id={p`requesterRole`}
            options={requesterRoleOptions()}
            value={findInOptions(requesterRoleOptions(), requesterRole)}
            onChange={(_event, requesterRoleOption) => setRequesterRole(requesterRoleOption?.value)}
            renderInput={(params) => (
              <TextField
                sx={errors.requesterRole !== undefined && !requesterRole ? { input: { color: '#8b0000 ' } } : {}}
                placeholder={`${t`requesterRole`} *`}
                {...params}
                error={errors.requesterRole !== undefined && !requesterRole}
                helperText={errors.requesterRole !== undefined && !requesterRole ? t`errors.required` : ''}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWrapper label={t`contactInformation`}>
            <FlatContactList
              items={flatContacts}
              add={addNewFlatContact}
              removeAt={removeFlatContactAt}
              replaceAt={replaceFlatContactAt}
            />
            <ErrorsAlert errorsArray={errors.contacts?.map((err) => Object.values(err)).flat(2)} />
          </FormWrapper>
        </Col>
      </Row>
      <ErrorsAlert errorsArray={errors.non_field_errors} />
      <ErrorsAlert errorsArray={errors.validation_errors} />
    </FormExpandableField>
  );
}

export default RequesterField;

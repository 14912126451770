import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { Children, cloneElement, ReactNode, useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

enum CheckboxState {
  Exclude = 'exclude',
  Include = 'include',
  Ignore = 'ignore',
  Indeterminate = 'indeterminate',
}

export default function BookingsFiltersList({
  color,
  label,
  children,
}: {
  color: string;
  label: string;
  children: ReactNode;
}) {
  const [parentState, setParentState] = useState<CheckboxState>(CheckboxState.Ignore);
  const [childStates, setChildStates] = useState<CheckboxState[]>(
    Children.map(children, () => CheckboxState.Ignore) || [],
  );

  useEffect(() => {
    if (childStates.every((checkbox) => checkbox.valueOf() === CheckboxState.Include)) {
      setParentState(CheckboxState.Include);
    } else if (childStates.every((checkbox) => checkbox.valueOf() === CheckboxState.Exclude)) {
      setParentState(CheckboxState.Exclude);
    } else if (childStates.every((checkbox) => checkbox.valueOf() === CheckboxState.Ignore)) {
      setParentState(CheckboxState.Ignore);
    } else setParentState(CheckboxState.Indeterminate);
  }, [childStates]);

  const handleChildStateChange = (index: number, state: CheckboxState) => {
    const newChildStates = [...childStates];
    newChildStates[index] = state;
    setChildStates(newChildStates);

    if (newChildStates.every((s) => s === CheckboxState.Include)) {
      setParentState(CheckboxState.Include);
    } else if (newChildStates.every((s) => s === CheckboxState.Exclude)) {
      setParentState(CheckboxState.Exclude);
    } else {
      setParentState(CheckboxState.Indeterminate);
    }
  };

  const handleChange = () => {
    let newState: CheckboxState;
    if (parentState === CheckboxState.Ignore) {
      newState = CheckboxState.Include;
    } else if (parentState === CheckboxState.Include) {
      newState = CheckboxState.Exclude;
    } else {
      newState = CheckboxState.Ignore;
    }
    setParentState(newState);
    setChildStates(new Array(childStates.length).fill(newState));
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={parentState === CheckboxState.Include ? <CheckCircleIcon /> : <CancelIcon />}
            indeterminateIcon={<RemoveCircleIcon />}
            checked={parentState !== CheckboxState.Ignore}
            indeterminate={parentState === CheckboxState.Indeterminate}
            onChange={handleChange}
            sx={{
              color,
              '&.Mui-checked': {
                color,
              },
              '&.MuiCheckbox-indeterminate': {
                color,
              },
            }}
          />
        }
        label={label}
        sx={{
          '.MuiFormControlLabel-label': {
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          },
        }}
      />
      <Box
        sx={{
          ml: 2,
        }}
      >
        {Children.map(children, (child, index) =>
          cloneElement(child as React.ReactElement<any>, {
            onStateChange: (state: CheckboxState) => handleChildStateChange(index, state),
            parentState,
          }),
        )}
      </Box>
    </Box>
  );
}
